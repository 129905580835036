import React from "react";
import {
  Row,
  Col,
  OverlayTrigger,
  Popover,
  PopoverContent,
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserService from "../../services/user/user.service";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import AppConfig from "../../config/app.config";
import { Link } from "react-router-dom";
import { secondsToHourse } from "../../helpers/core.helper";
import {
  newRealeseSyncStart,
  classicSyncStart,
  independentSyncStart,
  setSigninRedirectPath,
} from "../../store/home/home.actions";
import { selectEmployeeData } from "../../store/home/home.selectors";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ReactComponent as Play } from "../../assets/images/play-btn.svg";
import { ReactComponent as Right } from "../../assets/images/right-btn.svg";

const mapStateToProps = (state) => {
  return { employeeData: selectEmployeeData(state) };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      newRealeseSyncStart,
      classicSyncStart,
      independentSyncStart,
      setSigninRedirectPath,
    },
    dispatch
  ),
});

export class Watchlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      movieMap: [],
      episodeMap: [],
      checkingUpdate: false,
      watchListType: 0,
      defaultTab: "first",
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId != null
    ) {
      this.getMovieWatchList(true);
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: window.location.pathname,
      });
      const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL + window.location.pathname);
      const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
      window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
    }
  }

  getMovieWatchList = (isFetchEpisodeWatchList) => {
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      employeeId: this.props.employeeData?.employeeId,
      verticalId: AppConfig.API.VERTICAL_ID,
    };
    UserService.getMovieWatchList(payload)
      .then((res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0, movieMap: [] });
          NotificationManager.warning(
            res.error.response.data.message,
            "",
            2000
          );
        } else {
          if (res["status"]) {
            if (res["data"] != null) {
              if (isFetchEpisodeWatchList) {
                this.getEpisodeWatchList();
              }
              this.setState({
                movieMap: res["data"],
                isLoading: isFetchEpisodeWatchList ? 1 : 0,
              });
            } else {
              this.setState({ movieMap: [], isLoading: 0 });
            }
          } else {
            NotificationManager.error(res["message"], "", 2000);
            if (isFetchEpisodeWatchList) {
              this.getEpisodeWatchList();
            } else {
              this.setState({ isLoading: 0, movieMap: [] });
            }
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: 0 });
        NotificationManager.error(err, "", 2000);
      });
  };

  getEpisodeWatchList = () => {
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      employeeId: this.props.employeeData?.employeeId,
      verticalId: AppConfig.API.VERTICAL_ID,
    };
    UserService.getEpisodeWatchList(payload)
      .then((res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0, episodeMap: [] });
          NotificationManager.warning(
            res.error.response.data.message,
            "",
            2000
          );
        } else {
          if (res["status"]) {
            if (res["data"] != null) {
              this.setState({ episodeMap: res["data"], isLoading: 0 });
            } else {
              this.setState({ episodeMap: [], isLoading: 0 });
            }
          } else {
            this.setState({ isLoading: 0, episodeMap: [] });
            NotificationManager.error(res["message"], "", 2000);
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: 0, episodeMap: [] });
        NotificationManager.error(err, "", 2000);
      });
  };

  removeFromWatchList = (item, tagName) => {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId != null
    ) {
      this.setState({ isLoading: 1 });
      const payload =
        tagName === "Series"
          ? {
              episodeId: item.episodeId,
              movieId: item.seriesId,
              employeeId: this.props.employeeData?.employeeId,
              movieWatchListId: item.watchListId,
            }
          : {
              movieId: item.movieId,
              employeeId: this.props.employeeData?.employeeId,
              movieWatchListId: item.movieWatchListId,
            };
      UserService.addToWatchList(payload)
        .then((res) => {
          if (res.error != null) {
            NotificationManager.warning(
              res.error.response.data.message,
              "",
              2000
            );
            this.setState({ isLoading: 0 });
          } else {
            if (res["status"]) {
              NotificationManager.success(
                `${
                  tagName === "Series" ? "Episode" : "Movie"
                } Removed from My Watchlist.`,
                "",
                2000
              );
              this.setState({
                defaultTab: tagName === "Movie" ? "first" : "second",
              });
              if (tagName === "Movie") {
                const payloadData = {
                  subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
                  verticalId: AppConfig.API.VERTICAL_ID,
                  employeeId: this.props.employeeData?.employeeId,
                  loginEmployeeId: this.props.employeeData?.employeeId,
                };
                this.props.classicSyncStart(payloadData);
                this.props.newRealeseSyncStart(payloadData);
                this.props.independentSyncStart(payloadData);
                this.getMovieWatchList(false);
              } else if (tagName === "Series") {
                this.getEpisodeWatchList();
              }
            } else {
              NotificationManager.error(res["message"], "", 2000);
              this.setState({ isLoading: 0 });
            }
          }
        })
        .catch((err) => {
          NotificationManager.error(err, "", 2000);
          this.setState({ isLoading: 0 });
        });
    }
  };

  render() {
    return (
      <>
        {this.state.isLoading === 1 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: window.innerHeight,
              width: "100%",
            }}
          >
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </div>
        ) : (
          <div className="watchlist-container">
            <div className="watchlist-text">
              <p>MY WATCHLIST</p>
            </div>
            <Tabs defaultActiveKey={this.state.defaultTab} id="watchlist-tabs">
              <Tab eventKey="first" title="MOVIES" className="watchlist-title">
                {this.state.movieMap.length > 0 ? (
                  <Row sm="12" className="overflow-hidden" id="viewall-row">
                    {this.state.movieMap.map((item, key) => {
                      return (
                        <Col
                          xxl="3"
                          lg="3"
                          md="4"
                          sm="6"
                          key={key}
                          className="col-1-5 iq-mb-30"
                          id="view-all-movie1"
                        >
                          <div className="epi-box">
                            <div className="epi-img position-relative">
                              <img
                                src={
                                  AppConfig.API.MANU_IMAGE +
                                  item.movieId +
                                  "&random=" +
                                  Date.now()
                                }
                                className="img-fluid img-zoom"
                                alt=""
                                id="watchlist-img-set"
                              />
                              <div className="episode-play-info">
                                <div className="episode-play">
                                  <Link to={`/movie-details/${item.movieId}`}>
                                    <Play
                                      className="text-center"
                                      id="series-play"
                                    />
                                  </Link>
                                </div>
                                <div className="episode-play">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Popover id="popover-basic">
                                        <PopoverContent>
                                          {"Remove from Watchlist"}
                                        </PopoverContent>
                                      </Popover>
                                    }
                                  >
                                    <Right
                                      className="text-center"
                                      id="series-remove"
                                      onClick={(event) => {
                                        event.target.blur();
                                        this.removeFromWatchList(item, "Movie");
                                      }}
                                    />
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </div>
                            <div className="epi-desc p-3">
                              <Link to={`/movie-details/${item.movieId}`}>
                                <h5
                                  className="epi-name text-white mb-0"
                                  id="watchlist-episodename"
                                >
                                  {item.movieName}{" "}
                                </h5>
                              </Link>
                              <div className="d-flex align-items-center justify-content-between">
                                <span
                                  className="text-white"
                                  id="watchlist-date"
                                >
                                  {item?.releasedYear}
                                </span>
                                <span
                                  className="text-primary"
                                  id="set-seconds-hours"
                                >
                                  {" "}
                                  {secondsToHourse(item.movieLength)}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div
                    className="align-items-center justify-content-center row"
                    style={{ minHeight: window.innerHeight - 550 }}
                  >
                    <h4 className="series-movie-text">
                      You have no Movies on Your Watchlist
                    </h4>
                  </div>
                )}
              </Tab>
              <Tab eventKey="second" title="SERIES" className="watchlist-title">
                {this.state.episodeMap.length > 0 ? (
                  <Row sm="12" className="overflow-hidden" id="viewall-row">
                    {this.state.episodeMap.map((item, key) => {
                      return (
                        <Col
                          xxl="3"
                          lg="3"
                          md="4"
                          sm="6"
                          key={key}
                          className="col-1-5 iq-mb-30"
                          id="view-all-movie1"
                        >
                          <div className="epi-box">
                            <div className="epi-img position-relative">
                              <img
                                src={
                                  item.episodeImageUrl !== ""
                                    ? item.episodeImageUrl
                                    : AppConfig.API.EPISODE_IMAGE +
                                      item.episodeId +
                                      "&random=" +
                                      Date.now()
                                }
                                className="img-fluid img-zoom"
                                alt=""
                                id="watchlist-img-set"
                              />
                              <div className="episode-play-info">
                                <div className="episode-play">
                                  <Link
                                    to={`/series-details/${item.seriesId}/${item.seasonId}/${item.episodeId}`}
                                  >
                                    <Play
                                      className="text-center"
                                      id="series-play"
                                    />
                                  </Link>
                                </div>
                                <div className="episode-play">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Popover id="popover-basic">
                                        <PopoverContent>
                                          {"Remove from Watchlist"}
                                        </PopoverContent>
                                      </Popover>
                                    }
                                  >
                                    <Right
                                      className="text-center"
                                      id="series-remove"
                                      onClick={(event) => {
                                        event.target.blur();
                                        this.removeFromWatchList(
                                          item,
                                          "Series"
                                        );
                                      }}
                                    />
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </div>
                            <div className="epi-desc p-3">
                              <Link
                                to={`/series-details/${item.seriesId}/${item.seasonId}/${item.episodeId}`}
                              >
                                <h5
                                  className="epi-name text-white mb-0"
                                  id="watchlist-episodename"
                                >
                                  {item.seriesName}{" "}
                                </h5>
                              </Link>
                              <div className="d-flex align-items-center justify-content-between">
                                <span
                                  className="text-white"
                                  id="watchlist-season"
                                >
                                  {item.seasonName}
                                </span>
                                <span
                                  className="text-primary"
                                  id="set-seconds-hours"
                                >
                                  {new Date(item.publishDate).getFullYear()}
                                </span>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                id="episode-watchlist"
                              >
                                <span id="watchlist-episode">
                                  {item.episodeName}
                                </span>
                                <span
                                  className="text-primary"
                                  id="set-seconds-hours"
                                >
                                  {" "}
                                  {secondsToHourse(item.episodeLength)}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div
                    className="align-items-center justify-content-center row"
                    style={{ minHeight: window.innerHeight - 550 }}
                  >
                    <h4 className="series-movie-text">
                      You have no Series on Your Watchlist
                    </h4>
                  </div>
                )}
              </Tab>
            </Tabs>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Watchlist);
