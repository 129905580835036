import React from 'react'
import { Row, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectEmployeeData, selectLocation } from '../../store/home/home.selectors';
import { ReactComponent as VeuitLogo } from "../../assets/images/veuit-logo.svg";
import AboutModal from "../about/about";

const mapStateToProps = (state) => {
    return {
        selectLocation: selectLocation(state),
        employeeData: selectEmployeeData(state)
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {

        },
        dispatch
    )
})



export class FooterManu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routeName: "",
            isAboutModalVisible: false
        };
    }
    componentDidMount() {
        this.setState({ routeName: window.location.pathname })
    }
    componentDidUpdate(prevState, prevProps) {
        if (this.props.selectLocation.location !== null && this.props.selectLocation.location !== undefined && this.state.routeName !== this.props.selectLocation.location.pathname) {
            this.setState({ routeName: this.props.selectLocation.location.pathname })
        }
    }

    render() {
        return (
            <footer id="contact" className={`footer-one iq-bg-dark ${(this.state.routeName?.split("/")[1] === "podscast-details") ? "footer-podscast" : ""}`} style={{ zIndex: 1 }}>
                <div className="footer-top" style={{ paddingTop: '72px', paddingBottom: '80px' }}>
                    <Container fluid id="footer-container">
                        <Row className="footer-standard">
                            {/* <Col lg="1" md="12" className="mt-4 mt-lg-0 text-center" style={{ paddingBottom: '10px' }}>
                                <img src={logo} className="c-logo" alt="streamit" />
                            </Col>
                            <Col lg="6" md="12">
                                
                                <div className="widget text-left">
                                    <div className="menu-footer-link-1-container">
                                        <ul id="menu-footer-link-1" className="menu p-0">
                                            <li id="menu-item-7314" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314">
                                                <Link to="/terms-of-use" ><h6 style={{color:'#40bbfe'}}>Terms Of Use</h6></Link>
                                            </li>
                                            <li id="menu-item-7316" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316">
                                                   <Link to="/privacy-policy"><h6 style={{color:'#40bbfe'}}>Privacy Policy</h6></Link>
                                            </li>
                                            <li id="menu-item-7118" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118"
                                                style={{ paddingBottom: '15px' }}>
                                                <Link to="/faq" ><h6 style={{color:'#40bbfe'}}>FAQ</h6></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="widget text-left">
                                    <div className="textwidget">
                                        <p><small>© 2022 VEUIT NETWORK. All Rights Reserved. All videos and shows on this platform are trademarks of, and all related images and content are the property of, Veuit Network Inc. Duplication and copy of this is strictly prohibited.</small></p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="1" md="12"></Col>
                            <Col lg="3" md="6" className="mt-4 mt-lg-0">
                                <h6 className="footer-link-title" style={{color:'#40bbfe'}}>
                                    Follow Us :
                                </h6>
                                <ul className="info-share">
                                    <li><Link target="_blank" to="#"><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link target="_blank" to="#"><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link target="_blank" to="#"><i className="fa fa-instagram"></i></Link></li>
                                    <li><Link target="_blank" to="#"><i className="fa fa-linkedin"></i></Link></li>
                                    <li><Link target="_blank" to="#"><i className="fa fa-youtube-play"></i></Link></li>
                                </ul>
                                <ul className="info-share">
                                    <li><Link target="_blank" to="#"><i className="fa fa-envelope"></i></Link> Support@VEUIT.com</li>
                                </ul>
                            </Col> */}
                            <div className='set-veuit-footer'>
                                <div className='veuit-footer-logo'>
                                    <Link to="/">  <VeuitLogo className="c-logo" /></Link>
                                </div>
                                <div className="widget text-left">
                                    <div className="menu-footer-link-1-container" id='set-list-footer'>
                                        <ul id="menu-footer-link-1" className="menu p-0">
                                            <li id="menu-item-7314" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314">
                                                <Link to="/terms-of-use" ><h6 style={{ color: '#40bbfe' }} className="footer-list-text">Terms Of Use</h6></Link>
                                            </li>
                                            <li id="menu-item-7316" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316">
                                                <Link to="/privacy-policy"><h6 style={{ color: '#40bbfe' }} className="footer-list-text">Privacy Policy</h6></Link>
                                            </li>
                                            <li id="menu-item-7118" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118 faq-list">
                                                <Link to="/faq" ><h6 style={{ color: '#40bbfe' }} className="footer-list-text">FAQ</h6></Link>
                                            </li>
                                            <li id="menu-item-7118" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118 faq-list">
                                                <Link to="/mission-purpose" ><h6 style={{ color: '#40bbfe' }} className="footer-list-text">Mission and Purpose</h6></Link>
                                            </li>
                                            {this.props.employeeData != null && 
                                                <li id="menu-item-7118" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118 faq-list">
                                                    <h6 style={{ color: '#40bbfe' }} className="footer-list-text" onClick={() => this.setState({isAboutModalVisible: true})}>About</h6>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <ul className="info-share">
                                    <li><Link target="_blank" to={{ pathname: 'https://www.instagram.com/veuit.tv' }} ><i className="fa fa-instagram"></i></Link></li>
                                </ul>
                            </div>
                            <div className="widget text-left">
                                <div className="textwidget">
                                    <p>2022 VEUIT NETWORK. All Rights Reserved. All videos and shows on this platform are trademarks of, and all related images and content are the property of, Veuit Network Inc. Duplication and copy of this is strictly prohibited.</p>
                                </div>
                            </div>
                            <ul className="info-share-mob">
                                <Link target="_blank" to={{ pathname: 'https://www.instagram.com/veuit.tv' }}>
                                    <i className="fa fa-instagram"></i>
                                </Link>
                            </ul>
                            {/*  <Col lg="2" md="6" className="mt-4 mt-lg-0">
                                <div className="widget text-left">
                                    <div className="textwidget">
                                        <h6 className="footer-link-title" style={{color:'#40bbfe'}}>Join Our Newsletter!</h6>
                                        <p>Sign up today for updates on new movies, shows and channels!</p>
                                        <br />
                                        <Button type="button" target="_blank" href="" variant="primary next action-button" style={{ color: '#fff' }} >JOIN TODAY</Button>

                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                    </Container>
                </div>
                {this.state.isAboutModalVisible && 
                    <AboutModal visible={this.state.isAboutModalVisible} close={() => this.setState({isAboutModalVisible: false})} />
                }
            </footer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterManu)