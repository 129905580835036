import { Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import UserProfile from "../views/profile/user-profile";
import ChangePassword from "../views/profile/change-password";
import Home from "../views/home/home";
import MovieDetails from "../views/movie-details/movie-details";
import SeriesDetails from "../views/series-details/series-details";
import ChannelRegistration from "../views/veuit-channel/channel-registration";
import Faq from "../views/footer-page/faq";
import PrivacyPolicy from "../views/footer-page/privacy-policy";
import TermsOfUse from "../views/footer-page/terms-of-use";
import ViewAll from "../views/view-all/view-all-movie";
import Watchlist from "../views/watchlist/watchlist";
import PodscastDetails from "../views/podcast-details/podcast-details";
//import ViewAllPodcast from '../views/view-all/view-all-podcast'
import viewAllPodcast from "../views/view-all/view-all-podcast";
import Error from "../views/Error/Error";
//import NewChannelRegistration from '../views/veuit-channel/new-channel-registration';
//import ChannelRegistrationMain from "../views/veuit-channel/channel-registration-main";
import PaymentChannelSuccess from "../views/channel-creation/channel-payment-success";
import PaymentChannelPending from "../views/channel-creation/channel-payment-pending";
import PaymentChannelOngoing from "../views/channel-creation/channel-payment-ongoing";
import PaymentChannelFailure from "../views/channel-creation/channel-payment-failure";
import Purchases from "../views/purchases/purchases";
import PurchasesDetails from "../views/purchases-details/purchases-details";
import MissionPurpose from "../views/footer-page/mission-purpose";

const MainRoute = () => {
  return (
    <TransitionGroup>
      <CSSTransition
        // key={location.key}
        classNames="fade"
        timeout={300}
      >
        <Switch>
          <Route path="/user-profile" component={UserProfile} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/watchlist" component={Watchlist} />
          <Route path="/movie-details/:movieId" component={MovieDetails} />
          <Route
            path="/series-details/:seriesId/:seasonId/:episodeId"
            component={SeriesDetails}
          />
          <Route path="/series-details/:seriesId" component={SeriesDetails} />
          <Route path="/channel-registration" component={ChannelRegistration} />
          <Route path="/faq" component={Faq} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/view-all/:movieType" component={ViewAll} />
          <Route path="/purchases" component={Purchases} />
          <Route path="/purchases-details/:seriesId" component={PurchasesDetails} />
          <Route path="/mission-purpose" component={MissionPurpose} />
          <Route
            path="/podscast-details/:podcastId"
            component={PodscastDetails}
          />
          <Route path="/view-podcast" component={viewAllPodcast} />
          {/* <Route path="/new-channel-registration" component={NewChannelRegistration} /> */}
          {/* <Route
            path="/channel-registration-main"
            component={ChannelRegistrationMain}
          /> */}
          <Route
            path="/channel-payment-status-success"
            component={PaymentChannelSuccess}
          />
          <Route
            path="/channel-payment-status-success/:orderId"
            component={PaymentChannelSuccess}
          />
          <Route
            path="/channel-payment-status-ongoing/:orderId"
            component={PaymentChannelOngoing}
          />
          <Route
            path="/channel-payment-status-pending"
            component={PaymentChannelPending}
          />
          <Route
            path="/channel-payment-status-failure/:orderId"
            component={PaymentChannelFailure}
          />

          {/* homepage */}
          <Route path="/" exact component={Home} />
          <Route path="*" component={Error} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default MainRoute;
