import { http, caHttp } from "../../services/app/app.service";

const UserService = {
  /**
    //  * User Sign in api call old api//m1972463
     * @param {object} signInData - the signin payload
     */
  signIn: (signInData) => {
    return http
      .post("/veuitNetwork/m2001568", signInData)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * User Sign Up api call
   * @param {object} signUpData - the signup payload
   */
  signUp: (signUpData) => {
    return http
      .post("/veuitNetwork/m1363584", signUpData)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * send reset password api call
   * @param {object} payload - the post data payload
   */
  sendResetPasswordLink: (payload) => {
    return http
      .post("/veuitNetwork/m1363730", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * validate reset key api call
   * @param {object} payload - the post data payload
   */
  validateResetKey: (payload) => {
    return http
      .post("/veuitNetwork/m1363731", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * save reset password api call
   * @param {object} payload - the post data payload
   */
  saveResetPassword: (payload) => {
    return http
      .post("/veuitNetwork/m1363733", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get user profile in call api
   * @param {Long} employeeId - the employeeId
   */
  getUserProfile: (employeeId: Long) => {
    return http
      .get(`/commonrestapi/m1064731/${employeeId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * save user profile data api call
   * @param {object} payload - the post data payload
   * @param {Long} employeeId - the employeeId
   */
  updateUserProfile: (payload, employeeId: Long) => {
    return http
      .put(`/commonrestapi/m1065385/${employeeId}`, payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * update password api call
   * @param {object} payload - the post data payload
   */
  updatePassword: (payload) => {
    return http
      .post("/commonrestapi/m1303853", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get tab list Data api call
   * @param {object} payload - the post data payload
   */
  getTabListData: (payload) => {
    return http
      .post("/commonrestapi/m1288635", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get banner Data api call
   * @param {object} payload - the post data payload
   */
  getBannerData: (payload) => {
    return http
      .post("/veuitNetwork/m1363763", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get original series api call
   * @param {object} payload - the post data payload
   */
  getSerriesBannerData: (payload) => {
    return http
      .post("/veuitNetwork/m1512503", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get movie list by sub type api call
   * @param {object} payload - the post data payload
   */
  getMoviesListBySubType: (payload) => {
    return http
      .post("/veuitNetwork/m1363862", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get original series api call
   * @param {object} payload - the post data payload
   */
  getOriginalSerries: (payload) => {
    return http
      .post("/veuitNetwork/m1363886", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get movie detail by id api call
   * @param {object} payload - the post data payload
   */
  getMovieDetailById: (payload) => {
    return http
      .post("/veuitNetwork/m1364028", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get series detail by id api call
   * @param {object} payload - the post data payload
   */
  getSeriesDetailById: (payload) => {
    return http
      .post("/veuitNetwork/m1364092", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get Influencer list api call
   * @param {object} payload - the post data payload
   */
  getInfluencerListByChannelType: (payload) => {
    return http
      .post("/veuitNetwork/m1365020", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get barnds list api call
   * @param {object} payload - the post data payload
   */
  getBrandsList: (payload) => {
    return http
      .post("/veuitNetwork/m1365105", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get barnds partners list  api call
   * @param {object} payload - the post data payload
   */
  getBrandsPartnersList: (payload) => {
    return http
      .post("/veuitNetwork/m1365855", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get Discipline List  api call
   * @param {object} payload - the post data payload
   */
  getDisciplineList: (payload) => {
    return http
      .post("/veuitChannelRegistration/m1364578", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get Focus List By Discipline in call api
   * @param {Long} disciplineId - the employeeId
   */
  getFocusListByDiscipline: (disciplineId: Long) => {
    return http
      .get(`/veuitChannelRegistration/m1364580/${disciplineId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get Discipline List  api call
   * @param {object} payload - the post data payload
   */
  getProductList: (payload) => {
    return http
      .post("/veuitChannelRegistration/m1364591", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * validate domain  api call
   * @param {object} payload - the post data payload
   */
  validateDomain: (payload) => {
    return http
      .post("/veuitChannelRegistration/m1366232", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * validate domain  api call
   * @param {object} payload - the post data payload
   */
  regiserVeuitChannel: (payload) => {
    return http
      .post("/veuitChannelRegistration/m1364606", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get Faq List in call api
   *  - the employeeId  /veuitNetwork/m1363912
   *  * @param {object} payload - the post data payload
   */
  getFaqList: (payload) => {
    return http
      .post(`/commonrestapi/m1388407`, payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * add to watch list api call
   * @param {object} payload - the post data payload
   */
  addToWatchList: (payload) => {
    return http
      .post("/veuitNetwork/m1363975", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * validate subscription Id  api call
   * @param {object} payload - the post data payload
   */
  validateSubscriptionId: (payload) => {
    return http
      .post("/veuitChannelRegistration/m1382736", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get exclusive event list  api call
   * @param {object} payload - the post data payload
   */
  getExclusiveEventList: (payload) => {
    return http
      .post("/veuitNetwork/m1382734", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get Featured Channels List  api call
   * @param {object} payload - the post data payload
   */
  getFeaturedChannelsList: (payload) => {
    return http
      .post("/veuitNetwork/m1396635", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get Tv Channels List  api call
   * @param {object} payload - the post data payload
   */
  getTvChannelsList: (payload) => {
    return http
      .post("/veuitNetwork/m1396642", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * remove from watch list call api
   * @param {Long} movieWatchListId - the employeeId
   */
  removeFromWatchlist: (movieWatchListId: Long) => {
    return http
      .delete(`/veuitNetwork/m1363976/${movieWatchListId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * ganrate token in call api
   * @param {Double} amount - the employeeId
   */
  genrateToken: (amount: Double) => {
    return http
      .get(`/commonrestapi/m1383182/${amount}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get movie watch list  api call
   * @param {object} payload - the post data payload
   */
  getMovieWatchList: (payload) => {
    return http
      .post("/veuitNetwork/m1383469", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get episode watch list  api call
   * @param {object} payload - the post data payload
   */
  getEpisodeWatchList: (payload) => {
    return http
      .post("/veuitNetwork/m1383470", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * Create session for log
   * @param {object} payload - the post data payload
   * @param {object} header - the post data headers
   */
  createSessionForLog: (payload, header: object) => {
    return caHttp
      .post("/client/session", payload, { headers: header })
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * Create customer log
   * @param {object} payload - the post data payload
   * @param {object} header - the post data headers
   */
  createCustomerLog: (payload, header: object) => {
    return caHttp
      .post("/client/log", payload, { headers: header })
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get podcast list api call
   * @param {object} payload - the post data payload
   */
  getPodcastList: (payload) => {
    return http
      .post("/veuitNetwork/m1511755", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get trending lives list api call
   * @param {object} payload - the post data payload
   */
  getTrendingLivesist: (payload) => {
    return http
      .post("/veuitNetwork/m1511810", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get Channel Category api call
   * @param {object} payload - the post data payload
   */
  getChannelCategoryList: (payload) => {
    return http
      .post("/veuitNetwork/m1546706", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get movie detail by id api call
   * @param {object} payload - the post data payload
   */
  getPodcastDetailById: (payload) => {
    return http
      .post("/veuitNetwork/m1568824", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get payment status api call
   * @param {object} payload - the post data payload
   */
  getPaymentStatus: (payload) => {
    return http
      .post("/veuitNetwork/m1973326", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * buy movie or series
   * @param {object} payload - the post data payload
   */
  buyMovieOrSeries: (payload) => {
    return http
      .post("/veuitNetwork/m1972694", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * Save play, resume, pause and stop logs for movie and series
   * @param {object} payload - the post data payload
   */
  savePlayPauseResumeLogs: (payload) => {
    return http
      .post("/veuitNetwork/m1974025", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * update Transaction Address api call
   * @param {object} payload - the post data payload
   */
  updateTransactionAddress: (payload) => {
    return http
      .post("/veuitNetwork/m2088805", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * Get Dynamic Product List
   * @param {object} payload - the post data payload
   */
  getDynamicProductList: (payload) => {
    return http
      .post("/veuitChannelRegistration/m1509717", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get deck images
   * @param {object} payload - the post data payload
   */
  getDeckImages: (payload) => {
    return http
      .post("/deck/m1357310", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   *get Country list
   * @param {object} payload - the post data payload
   */
  getCountryList: (subscriptionId, verticalId) => {
    return http
      .get(`/commonrestapi/m1346623/${subscriptionId}/${verticalId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get State list
   * @param {object} payload - the post data payload
   */
  getStateList: (countryId) => {
    return http
      .get(`commonrestapi/m1346624/${countryId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * add employee Billing Address
   * @param {object} payload - the post data payload
   */
  addBillingAddress: (payload) => {
    return http
      .post("/commonadmin/m2120960", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get employee Billing Address
   * @param {object} payload - the post data payload
   */
  getBillingAddress: (payload) => {
    return http
      .post("/commonadmin/m2120991", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   *delete employee Billing Address
   * @param {Long} addressId - the employeeId
   */
  deleteBillingAddress: (addressId: Long) => {
    return http
      .delete(`/commonadmin/m2120992/${addressId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get employee Billing Address
   * @param {object} payload - the post data payload
   */
  getBluesnapPfToken: (payload) => {
    return http
      .post("/veuitNetwork/m2195839", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * add card detail
   * @param {object} payload - the post data payload
   */
  addCard: (payload) => {
    return http
      .post("/veuitNetwork/m2195841", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get card detail
   * @param {object} payload - the post data payload
   */
  getCard: (payload) => {
    return http
      .post("/veuitNetwork/m2195843", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * get Purchase series list detail
   * @param {object} payload - the post data payload
   */
  getPurchaseSeriesList: (payload) => {
    return http
      .post("/veuitNetwork/m2202355", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * @param {object} payload - to disable Session Log
   */
  logOutSession: (payload) => {
    return http
      .post(`/commonadmin/m2724875`, payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
};

export default UserService;
